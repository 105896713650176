import * as React from "react";

const ShapeTwo = ({ top = "5%", right = "5%", width = 50, height = 50 , color, transform }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      top: top,
      right: right,
      width: width,
      height: height,
      transform: transform
    }}
    version="1"
    viewBox="0 0 375 375"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="m148.016 121.645-.543-.47-56.68-48.59c-26.676 46.978 32.289 8.985 44.875 62.345.055.215.16.761.305.597ZM172.164 93.723l-16.785 1.504 2.207 16.023.27 1.953c15.609-7.617 31.367-8.605 47.3-1.754l.082-1.238 1.047-15.926-14.836-1.207-3.781-23.871-12.293-.582-3.207 25.098Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m172.887 69.098 16.847.21 4.993-51.511-27.829.473ZM155.379 95.227l10.805-.97-16.797-70.538-26.078 16.312ZM197.254 93.555l9.031.73 32.903-52.808-25.215-17.493ZM181.957 169.902c5.176-16.422 18.54-24.777-.516-47.855-22.726 23.543-5.851 32.254.516 47.855M122.063 172.852c-27.536-18.61-40.864 1.539-50.141-30.454 29.715-8.984 34.062 22.602 50.14 30.454M151.316 174.863c18.012 20.5 20.32.297 0 22.754-12.582-14.836-20.148-7.566 0-22.754M216.457 177.39c-18.012 20.497-20.32.298 0 22.75 12.578-14.831 20.145-7.562 0-22.75M125.68 216.79c-26.618 19.898-40.89.41-48.633 32.804 30.11 7.558 32.945-24.196 48.633-32.805M117.336 193.934c-33.195.23-31.773 24.406-58.387 3.257 18.387-24.242 40.809-.632 58.387-3.257M217.75 121.645l.543-.47 56.676-48.59c26.68 46.978-32.29 8.985-44.875 62.345-.055.215-.156.761-.301.597Zm0 0"
    ></path>
    <path
      fill={color}
      d="M195.258 16.371q.001 1.407-.274 2.785a14 14 0 0 1-.812 2.68q-.54 1.3-1.32 2.473a14.29 14.29 0 0 1-6.41 5.258 14.3 14.3 0 0 1-5.465 1.09q-1.407-.002-2.79-.278a14.3 14.3 0 0 1-5.148-2.133q-1.167-.782-2.164-1.773a14.5 14.5 0 0 1-1.773-2.164 14 14 0 0 1-1.32-2.473 14 14 0 0 1-.813-2.68 14.2 14.2 0 0 1 0-5.57q.272-1.381.812-2.68a14.29 14.29 0 0 1 5.258-6.41q1.173-.78 2.469-1.32a14.3 14.3 0 0 1 5.469-1.086q1.407 0 2.785.273a14.3 14.3 0 0 1 2.68.813q1.298.54 2.468 1.32t2.164 1.777a14.3 14.3 0 0 1 3.098 4.633 14.2 14.2 0 0 1 1.086 5.465m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m148.016 258.527-.543.47-56.68 48.589c-26.676-46.98 32.289-8.988 44.875-62.348.055-.21.16-.758.305-.593ZM172.164 286.45l-16.785-1.509 2.207-16.023.27-1.95c15.609 7.614 31.367 8.606 47.3 1.75l.082 1.239 1.047 15.926-14.836 1.21-3.781 23.868-12.293.586-3.207-25.098Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m172.887 294.344 16.847-.207 4.993 51.511-27.829-.476ZM155.379 284.941l10.805.973-16.797 70.54-26.078-16.313ZM197.254 286.617l9.031-.734 32.903 52.808-25.215 17.493ZM217.75 258.527l.543.47 56.676 48.589c26.68-46.98-32.29-8.988-44.875-62.348-.055-.21-.156-.758-.301-.593Zm0 0"
    ></path>
    <path
      fill={color}
      d="M197.547 185.445a14.04 14.04 0 0 1-2.371 7.813 14 14 0 0 1-1.746 2.133 14 14 0 0 1-2.133 1.746q-1.154.773-2.43 1.3a14.036 14.036 0 0 1-8.125.8c-.906-.178-1.785-.448-2.64-.8a14 14 0 0 1-2.43-1.3 14 14 0 0 1-3.879-3.88 14.2 14.2 0 0 1-1.3-2.429 14.036 14.036 0 0 1-.8-8.125 14.07 14.07 0 0 1 5.979-8.95 14.4 14.4 0 0 1 2.43-1.3 14.066 14.066 0 0 1 19.445 12.992m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M246.55 172.852c27.54-18.61 40.864 1.539 50.141-30.454-29.714-8.984-34.058 22.602-50.14 30.454M242.934 216.79c26.62 19.898 40.89.41 48.636 32.804-30.11 7.558-32.945-24.196-48.636-32.805M251.277 193.934c33.2.23 31.778 24.406 58.387 3.257-18.387-24.242-40.809-.632-58.387-3.257M182.34 200.621c5.176 16.422 18.539 24.777-.516 47.852-22.726-23.543-5.851-32.25.516-47.852m0 0"
    ></path>
    <path
      fill={color}
      d="M195.258 350.887a14.2 14.2 0 0 1-1.086 5.465q-.54 1.299-1.32 2.468a14.3 14.3 0 0 1-6.41 5.262q-1.302.536-2.68.813-1.378.272-2.785.273c-.938 0-1.868-.094-2.79-.273a14.6 14.6 0 0 1-2.68-.813q-1.295-.54-2.468-1.32a14.29 14.29 0 0 1-5.258-6.41 14 14 0 0 1-.812-2.68 14.2 14.2 0 0 1-.278-2.785q.001-1.406.278-2.79.272-1.377.812-2.679a14.3 14.3 0 0 1 3.094-4.633 14.5 14.5 0 0 1 2.164-1.777q1.173-.782 2.469-1.317 1.302-.54 2.68-.816 1.382-.272 2.789-.273 1.407.001 2.785.273 1.378.277 2.68.816a14.1 14.1 0 0 1 4.632 3.094 14.5 14.5 0 0 1 1.778 2.164q.78 1.173 1.32 2.469a14.3 14.3 0 0 1 1.086 5.469m0 0"
    ></path>
  </svg>
);

export default ShapeTwo;
