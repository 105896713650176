import React, { useState } from 'react';

const BurgerMenu = ({onClick}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
    if (onClick) { 
      onClick();
    }
  };

  const s = 2; 


  const styles = {
    burgerWrapper: {
      cursor: 'pointer',
      margin: 'auto',
      width: `${s * 10}px`,
      height: `${s * 8}px`,
    },
    hamburger: {
      background: 'black',
      width: `${s * 10}px`,
      height: `${s}px`,
      position: 'relative',
      transition: 'background 10ms 300ms ease',
      transform: `translateY(${s * 4}px)`,
    },
    hamburgerBefore: {
      transition: 'top 300ms 350ms ease, transform 300ms 50ms ease',
      position: 'absolute',
      background: 'black',
      width: `${s * 10}px`,
      height: `${s}px`,
      content: '""',
      top: `${s * -3}px`,
    },
    hamburgerAfter: {
      transition: 'top 300ms 350ms ease, transform 300ms 50ms ease',
      position: 'absolute',
      background: 'black',
      width: `${s * 10}px`,
      height: `${s}px`,
      content: '""',
      top: `${s * 3}px`,
    },
    menuTriggerChecked: {
      background: 'transparent',
    },
    menuTriggerCheckedBefore: {
      transition: 'top 300ms 50ms ease, transform 300ms 350ms ease',
      top: '0',
      transform: 'rotate(45deg)',
    },
    menuTriggerCheckedAfter: {
      transition: 'top 300ms 50ms ease, transform 300ms 350ms ease',
      top: '0',
      transform: 'rotate(-45deg)',
    },
    hidden: {
      display: 'none',
    },
  };

  return (
    <div style={styles.body} className="navbar-hamburger dot">
      <input
        type="checkbox"
        id="menu-trigger"
        checked={menuOpen}
        onChange={handleMenuToggle}
        style={styles.hidden}
      />
      <label htmlFor="menu-trigger" style={styles.burgerWrapper}>
        <div
          style={{
            ...styles.hamburger,
            ...(menuOpen && styles.menuTriggerChecked),
          }}
        >
          <div
            style={{
              ...styles.hamburgerBefore,
              ...(menuOpen && styles.menuTriggerCheckedBefore),
            }}
          />
          <div
            style={{
              ...styles.hamburgerAfter,
              ...(menuOpen && styles.menuTriggerCheckedAfter),
            }}
          />
        </div>
      </label>
    </div>
  );
};

export default BurgerMenu;