import React, { useEffect, useState } from 'react';

const PayhipCheckoutButton = ({ productId, buttonText, customMessage, className }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  
  useEffect(() => {

    if (window.Payhip) {
      setScriptLoaded(true);
      return;
    }
   
    const loadScript = async () => {
      const script = document.createElement('script');
      script.src = 'https://payhip.com/payhip.js';
      script.async = true;
      document.body.appendChild(script);
      
      script.onload = () => {
        setScriptLoaded(true);
      };
    };
    
    loadScript();
  }, []);
  
  const handleCheckout = () => {
    if (scriptLoaded && window.Payhip) {
      window.Payhip.Checkout.open({
        product: productId,
        message: customMessage || ''
      });
    } else {
      console.error('Payhip script not loaded yet');
    }
  };
  
  return (
    <button 
      onClick={handleCheckout}
      disabled={!scriptLoaded}
      className={className}
    >
      {buttonText || 'Buy Now'}
    </button>
  );
};

export default PayhipCheckoutButton;