import React from "react";
import { Link } from 'react-router-dom';
import ShapeOne from "./icons/ShapeOneIcon";

const BooktitleSection = () => {
  return (
    <div id="booktitle">
      <div className="booktitle-mobile">
        <div className="booktitle">
          <h1  className="booktitle_title">VIAJ<span className="small-text-book_landing">E</span> AL C<span className="small-text-book_landing">E</span>NT<span className="small-text-book_landing">R</span>Ø D<span className="small-text-book_landing">E</span> LA V<span className="small-text-book_landing">O</span>LU<span className="small-text-book_landing">N</span>TAD DE DIOS</h1>
        </div>
        <div className="booktitle_svg">
          <ShapeOne color={"#d3a518"} width={75} height={75} position={"static"}/>
        </div>
      </div>  
      <div className="booktitle_text">
            <h2>Como equiparte, descubrir tu destino,</h2>
            <h2>Y comenzar el viaje al centro de su voluntad</h2>
      </div>
      <div className="booktitle_button-container">
        <a className="booktitle_button button" href="#checkout">COMPRAR YA</a>
      </div>
    </div>
  );
};

export default BooktitleSection;