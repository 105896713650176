import React from "react";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-left_container">
        <h1>ÁLVAR<span className="small-text_footer">O</span> P<span className="small-text_footer">O</span>R<span className="small-text_footer">R</span>AS</h1>
        <p>© 2025 Álvaro Porras.</p>
        <p>All Rights Reserved. Privacy Policy</p>
      </div>
      <div className="footer-right_container">
        <h2>MENÚ</h2>
        <p><a href="/">ACERCA DEL AUTOR</a></p>
        <p><a href="/viaje">VIAJE AL CENTRO DE LA VOLUNTAD DE DIOS</a></p>
        <p><a href="/libro">LEER UNA PORCION GRATUITA</a></p>
      </div>
    </div>
  );
};

export default Footer;