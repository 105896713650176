import React from "react";
import ReactDOM from "react-dom";
import "../excerpt.css";
import "../components/ExcerptLandingPage"
import ExcerptLandingPage from "../components/ExcerptLandingPage";
import CheckoutSection from "./CheckoutSection";
import Footer from "../components/Footer"
import Navbar from './Navbar';
import ShapeTwo from "./icons/ShapeTwoIcon";

const ExcerptPage = () => {
  return (
    <div className="excerpt-page">
      <ExcerptLandingPage />
      <Navbar />
      <div id="text-container">
        <h1>Primero algo sobre su padre</h1>
        <div className="text-section">
          <p><span className="first-word">José</span> es otro ejemplo de alguien que fue sacado de su zona de confort para ser llevado al centro de la voluntad de Dios. Todas sus desventuras, dolores, crisis e injusticias tuvieron un propósito mayor que fue envuelto en sueños de gloria desde su juventud. Parece ser que eso de los sueños lo heredó de su padre Jacob, quien también experimentó el ser sacado de su zona de comodidad para ser llevado por las alas de la voluntad perfecta de Dios. Veamos algunos detalles de su historia.</p>
          <p>Al inicio de este relato familiar, nos encontramos a Jacob y su hermano Esaú viviendo bajo la tutela y ojo escrutador de Isaac. No es mi intención hacer toda una historia sobre Jacob, pero quiero dejarles saber que este hombre ha sido malamente juzgado por toda la comunidad evangélica. Yo me declaro un defensor de Jacob. En algunas ocasiones, he escuchado a predicadores, llenar el nombre de Jacob con infinidad de calificativos despectivos. Sin embargo, veamos lo que nos dice Génesis 25:26 (BTLA): “después de Esaú nació su hermano, al que llamaron Jacob porque nació agarrado del talón de Esaú. Isaac tenía sesenta años cuando los niños nacieron”.</p>
          <p>Así que su nombre significa “trabado al calcañar”, debido a que salió aferrado al talón de su hermano, hecho que su padre notó en ese momento ya que despuntaba algo de su carácter. Tal vez Isaac interpretó que, de alguna forma, su hijo estaba tomando alguna ventaja, pero nada más. Estamos claros que Jacob incurre en algunas acciones negativas que nos pueden hacer pensar que sí traía un lado oscuro. Puede que esas acciones justifiquen que le llamen truhan, sinvergüenza, truculento, mentiroso, estafador, engañador, aventajado, etc. Pero casi todos olvidan que, en sus encuentros con Dios, se produjeron cambios radicales en su carácter y conducta, al punto de ser llamado Israel, príncipe de Dios.</p> 
          <p>Por otro lado, quisiéramos señalar a quien sí tiene una gran responsabilidad en esta telenovela de la vida de Jacob, que es su madre Rebeca, de la cual nunca he escuchado queja alguna. Sin embargo, ella fue el gran personaje tras bambalinas que embarcó, fraguó y manipuló las circunstancias en la vida de Jacob su hijo. Todo apunta a que ella estaba obsesionada con un desmedido deseo de que su segundo hijo obtuviera la primogenitura en lugar de Esaú, su primogénito. “Ahora, pues, hijo mío, obedece a mi voz en lo que te mando” (Génesis 27:8). Esas fueron las palabras de Rebeca a Jacob, pues ella era quien tenía en mente este plan para engañar a Isaac. La historia bíblica nos descubre esta trama astuta y retorcida que ella había fraguado.</p>
          <p>Por supuesto que Jacob pudo oponerse a lo sugestivo del plan de su madre y allí estuvo su error, participó sin objeciones, incluso anticipando cómo sortear el asunto de que “Esaú mi hermano es hombre velloso, y yo lampiño” (v.11). Esto deja entrever su deseo de participar activamente en la trama, pensando cómo engañar a su padre y de ahí, su culpa. Por otro lado, Jacob temía ser descubierto porque entonces tendría como consecuencia una maldición en vez de una bendición. Así que, Raquel se jugó aquel chance diciéndole sin reparos: “Hijo mío, sea sobre mí tu maldición; solamente obedece a mi voz” (Génesis 27:13). Algo así como: “Tú tranquilo…que, si esto es descubierto, yo me voy a echar toda la culpa y tú saldrás bien librado”. La señora era toda una caja de sorpresas.</p> 
          <p>Pero volvamos a la historia. Jacob vive en la casa de su padre, bajo la tutela e instrucción de su madre: tienen criados, esclavos, ganados, economía, es amado, respetado, está en una zona confortable de vida. Sin embargo, todo estaba a punto de resquebrajarse bajo el peso del propósito eterno de Dios, que vendría tácitamente en esta circunstancia para llevarlo al centro de su voluntad. Su travesía comienza al ser descubierto, pues ha suplantado a su hermano Esaú “exitosamente” y ha recibido la bendición que por ley le correspondía a éste como primogénito de la casa.</p>
          <p>En vista de la situación, decide huir para evitar ser muerto bajo el ardor de la ira de Esaú. Debe abandonar todo lo que disfruta. Sin duda, es forzado a abandonar su zona de confort y ahí es donde tiene su primer encuentro con Dios. El Altísimo se revela a Jacob de una forma sobrenatural. Dios deja de ser una historia bien contada por su abuelo Abraham y su padre Isaac para convertirse en una realidad inmensa y gloriosa. Dios se le aparece en un sueño en el que lo terrenal se une con lo celestial y entonces el Eterno se descubre ante sí, diciéndole: “Yo soy Jehová, el Dios de Abraham tu padre, y el Dios de Isaac; la tierra en que estás acostado te la daré a ti y a tu descendencia” (Génesis 28:13).</p>
          <p>Jacob tendría siete encuentros con el Dios altísimo, y recibiría ratificación del pacto prometido a su abuelo y a su padre, además de protección, consejo, dirección, liberación, propósito y perdón. Pero, claro, mucho de lo que sucedió en su camino de vida, tuvo que ver con situaciones completamente inesperadas. No debe extrañarnos que ese mismo tipo de situaciones pueden sucedernos a todos nosotros, pues son parte del equipaje en este viaje al centro de Su voluntad. Lo más relevante es que, al igual que Jacob, nuestra esperanza es que tengamos ese encuentro personal y puntual con Dios, y que él se revele a nosotros como nunca antes lo habíamos conocido.</p>
          <p>Jacob tuvo ese encuentro personal con el Ángel de Jehová y, en la lucha por obtener su bendición, Dios le dejó una marca visible al desencajar su muslo. La cojera sería un constante recordatorio de que la bendición que recibió sucedió por encima de lo natural, pues un cambio de nombre —uno que habla del poder transformador de Dios—ocurriría en su vida, ya no sería más Jacob, sino Israel, Príncipe de Dios.</p>
        </div>
      </div>
      <div id="line-section_one">
        <div className="background-line line4"></div>
        <div className="background-line line5"></div>
      </div>
      <div id="text-container">
        <h1>José en la escena de Jacob</h1>
        <div className="text-section">
          <p><span className="first-word">Y</span> entonces nace José, hijo mayor de su segunda esposa llamada Raquel que —de acuerdo con el relato bíblico y al amor y deseo de Jacob— debió ser la primera, misma que concibió a Benjamín, el menor de la prole. Ambos nacieron en el invierno de su vida. A partir de aquí, y en este capítulo dos de la historia, la trama se volvería muy densa y siniestra con tintes de novela dramática y sentimental, matizadas con algo de acción al estilo Hollywood.</p>
          <p>El nacimiento de José debió ser un gran regalo del Altísimo para su madre Raquel, pues esta no podía concebir. El desarrollo de esta parte de la historia se encuentra en el libro del Génesis en los capítulos 29 y 30. Aquí aparece Labán, personaje siniestro, interesado, calculador, astuto y quien llegó a ser el suegro de Jacob, que no tuvo reparos en sacar ventaja del enamoramiento de este por Raquel, su hija menor.</p>
          <p>Labán fraguó un plan para hacerse de años de servicio y de trabajo arduo por parte de Jacob, mediante un ardid bien elaborado. De un plumazo le consiguió esposo a su solterona hija mayor —Lea— que, por orden suya, sustituyó a su hermana en la noche de bodas.</p> 
          <p>Después de siete largos años de espera, Jacob amaneció casado con Lea en vez de estar desposado con el amor de su vida, Raquel. Labán vuelve a aprovecharse de la situación consiguiendo otra buena cantidad de años de trabajo para que pudiera al fin llegar a la cima del cielo con su amada Raquel. Y es aquí donde la situación se pone densa para Lea, pues era objeto de un profundo menosprecio, producto quizás de este engaño infame.</p>
          <p>Sin embargo, aquello no pasó inadvertido para Dios. “Y vio Jehová que Lea era menospreciada, y le dio hijos; pero Raquel era estéril” (Génesis 29:31). Parece implícito que aun cuando la fertilidad de Lea era evidente y venía por decreto divino, la esterilidad de Raquel tenía algo que ver con su mal proceder. Al leer el capítulo 30 vemos como, en su naturaleza humana caída, Raquel es presa de poderosas emociones como la envidia, los celos y su orgullo lesionado.  Todo esto, sumado a la fertilidad de su hermana, produjo una enorme presión que terminó por resquebrajar su corazón. “Viendo Raquel que no daba hijos a Jacob, tuvo envidia de su hermana, y decía a Jacob: Dame hijos, o si no, me muero” (Génesis 30:1).</p>
          <p>Esa situación llevó a Raquel por un camino alterno basado en la frustración, sumamente obstinada en darle “hijos” aunque fuera de vientres distintos al suyo. Bilha y Zilpa fueron las madres adoptivas de sus “hijos”. Sin embargo, los constantes roces entre las dos hermanas continuaron y siempre que Raquel quería imponerse sobre Lea, esta concebía más hijos, prolongando así el sufrimiento de Raquel. Al final, en el versículo 22 de este capítulo, en un acto soberano y obedeciendo a su plan eterno, el Dios de toda misericordia escuchó los ruegos de Raquel y le concedió un hijo, a quien puso por nombre José, personaje central de nuestro capítulo.</p>
          <p>El nacimiento de José debe haber iluminado el rostro del ya entrado en años Jacob. Su corazón, siempre ligado a Raquel, tuvo un sesgo que se caracterizó por mostrar siempre sus preferencias afectivas. Con Raquel es entendible, pero luego mostró esa preferencia por José. En el primer caso, su menosprecio a Lea hizo que Dios le diera hijos a ella y la vindicara de alguna manera. Con su hijo, esta preferencia se convirtió en un imán que le atrajo a José innumerables prejuicios y odios gratuitos de sus otros hermanos. Para nosotros, que vemos la historia a distancia, es importante aplicar lo sucedido para nuestro propio provecho: hay que ser equilibrados en esto del afecto a nuestros hijos. Hay que ser justos y equitativos con todos, aunque nos sintamos inclinados afectivamente un poco más por alguno de ellos.</p>
          <p>Jacob le confecciona una túnica de colores a su hijo José como muestra de su preferencia y favor manifiesto. Además, le asigna tareas de vigilancia a sus hermanos y para colmo, José tiene dos sueños que dan la impresión clara e inequívoca de que él estaría en una posición de privilegio por encima de sus hermanos. El relato de sus dos sueños desató pasiones poderosas en ellos, que alimentados por el combustible de los celos y la envidia decidieron deshacerse de su creciente problema. Aquí arranca este viaje de José hacia el centro de la voluntad de Dios.</p>          
        </div>
      </div>
      <div id="line-section_one">
        <div className="background-line line6"></div>
        <div className="background-line line9"></div>
      </div>
      <div id="text-container">
        <h1>José es sacado de su zona de confort</h1>
        <div className="text-section">
          <p><span className="first-word">Creo</span> que en este punto ya mis lectores tienen este patrón bien claro. Dios nos saca de cualquiera que sea nuestro estancamiento conformista a través de situaciones de crisis que —por lo general— no entendemos; y que seguramente, le estaremos cuestionando de manera acalorada y apuntando con nuestro índice hacia el cielo. ¡Calma amigos, el Santo Espíritu de Dios está trabajando de manera misteriosa! No está interesado en divertirse contigo ni conmigo al llevarnos al conflicto; por el contrario, está apuntando a un proceso de vida, en el que veremos la manifestación de su gloria y podremos al final dar reconocimiento a su grandeza al verlo actuar de manera sobrenatural “en el centro de su voluntad”.</p>
          <p>Es posible que los designios del cielo traigan situaciones inesperadas que dan la impresión de que, es más bien el diablo quien está tratando de hacernos tropezar o de añadir sufrimiento a nuestra vida. No estoy diciendo que satanás no sea un adversario real o que no pueda desordenar todo a nuestro alrededor, pero su radio de acción está supeditado al ámbito de la mentira. Todo su poder está sostenido por el engaño; por eso, se le llama “el padre de mentira”. Él falsea la verdad con medias verdades, incrusta sus dardos de fuego cuando prestamos atención a su “conque Dios te ha dicho”. Génesis 3:1-d.</p>
          <p>Nuestro llamado, al contrario, es a caminar en la Palabra de Verdad. Un predicador y maestro de clase bíblica, hizo esta extraña pregunta a sus estudiantes: “¿Qué podría hacer un tiburón en un parqueo de vehículos en New York?”. Hubo múltiples respuestas, pero la más acertada fue esta: “¡Morirse!”. Y eso es cierto, dijo el predicador, porque cuando sacas al tiburón de su ambiente natural empieza a morir. Sin embargo, si lo llevas al mar y te metes con él estarás en serios problemas pues es el depredador más temible de los océanos.</p>
          <p>Así mismo es con Satanás, cuando caminas en el ámbito de la mentira le entregas todo el control y el poder a él, pero si lo llevas al campo de la verdad, pronto empezará a asfixiarse y se alejará con el rabo entre las patas. Jesús nos dio testimonio de esto en Mateo 4 cuando en repetidas ocasiones respondió con un sonoro “¡Escrito está!” ante las medias verdades e insinuaciones del maligno en su momento de mayor debilidad. La victoria es nuestra y está escondida en la verdad eterna de Dios, su Palabra poderosa.</p> 
          <p>Volvamos con José, quien está experimentando situaciones adversas que lo mantienen fuera de su zona de confort. Al igual que él, las circunstancias inesperadas nos pueden dejar un signo de interrogación tatuado en el corazón. Fue acusado falsamente, echado en la cárcel, olvidado, etc; situaciones que pueden dar al traste con la vida de cualquiera. Y es en este punto, que sacudimos nuestra cabeza de forma negativa porque es casi imposible engranar en espacio-tiempo, como se dará el cumplimiento del plan de Dios para nosotros. Da la impresión que caminar en el centro de Su voluntad será más complicado de lo que pensamos, pero al igual que Jose, la recompensa es enorme. Permanecer sin tocar retirada, traerá extraordinarias manifestaciones y grandes bendiciones  en nuestro peregrinar aquí en la tierra.</p>
          <p>Es muy posible que, al igual que nuestro personaje, hoy te sientas abandonado en medio de la nada, pero debes tener la seguridad de que Dios está obrando. Nuestra tarea más difícil es reconocer el sello de Dios en nuestra tribulación, la mano del Altísimo posada sobre nuestra vida cuando parece que todos nos han abandonado. O tal vez no…Examinemos nuestras vidas a la luz de lo que ocurrió con José.</p>
          <p>Sus hermanos determinan matarlo, pero al final lo tiran en una cisterna y lo venden a unos mercaderes esclavistas (Génesis 37:20-24, 27). Cuando llega a Egipto, es comprado por Potifar, oficial de Faraón (Génesis 39:1). Sin embargo, como era de hermoso parecer, la esposa de Potifar puso sus ojos en él y empezó un acoso de carácter sexual. Al no acceder a sus pretensiones, fue acusado injustamente y enviado a prisión (Génesis 39:1-20). Estando ahí, interpreta sendos sueños que tuvieron el copero y el panadero del rey que también estaban presos.</p>
          <p>Lo que José interpretó para ambos personajes fue acertado: positivo para el copero; negativo para el panadero. Por eso, al copero le dijo: “Acuérdate, pues, de mí cuando tengas ese bien, y te ruego que uses conmigo de misericordia, y hagas mención de mí a Faraón, y me saques de esta casa” (Gen. 40:14). Dicha petición no fructificó, pues “el jefe de los coperos no se acordó de José, sino que le olvidó” (40:23). Estar en prisión fue un tiempo de dura prueba para José. Aunque la escritura hace esta declaración en Génesis 39:21: “Mas el SEÑOR estaba con José y le extendió su misericordia, y le concedió gracia ante los ojos del jefe de la cárcel”. Todo eso era solamente una manifestación del favor de Dios con la vida de José para llevarlo al cumplimiento de su designio eterno. No crean por un solo segundo que José planeaba retirarse de viejo en aquella ingrata cárcel del olvido.</p>
          <p>En Génesis 41 vemos que pasan otros dos años y ahora es el propio Faraón quien tiene un sueño perturbador (1-8). Al fin, el copero se acuerda de José y lo trae ante Faraón, a quien le interpreta el sueño (9-14) Tan impresionado estaba el monarca con la interpretación, que supo discernir que en él reposaba el Espíritu de Dios (38) y entonces lo promovió diciéndole: “Tú estarás sobre mi casa, y por tu palabra se gobernará todo mi pueblo; solamente en el trono seré yo mayor que tú” (40). Al fin, aquel destino de gloria y propósito prometido a José desde temprana edad, había trascendido a todas sus pruebas y circunstancias, para dar lugar al plan eterno de Dios y al fin, posicionarlo en el centro de su voluntad.</p>
        </div>
      </div>
      <div id="text-container">
        <h1>En conclusión</h1>
        <div className="text-section">
          <p><span className="first-word">Debe</span> ser alentador —a la vez que retador— para nosotros que miramos desde la distancia de nuestra era moderna, ver a José caminando en medio de sus circunstancias en una época de esclavitud e injusticias, sin olvidar jamás sus valores y reconociendo que era Dios quien lo sostenía en ese valle de sombra y de muerte. Su mayor prueba no fue ser vendido como esclavo por su familia o encarcelado injustamente, no fue tampoco el ser olvidado por sus compañeros. Su mayor prueba fue mirarse a sí mismo en medio de sus circunstancias, en aquellos años de injusticia sin límites y pensar: “¿Cómo cumplirás tu propósito en mí? ¿Cuándo llegaré a disfrutar del destino que me fue trazado si estoy abandonado en una cárcel? Los años pasan y no veo salida a mi crisis, no encuentro la puerta, no te veo intervenir de ninguna manera”.</p>
          <p>Esa debió ser su mayor prueba y esa puede ser la tuya. Alentémonos pues, con su enseñanza, no nos desanimemos en nuestras crisis, no desfallezcamos en nuestra prueba, no retrocedamos en nuestro llamado, Dios está trabajando para llevarnos a nuestro destino. Tal vez tu queja es que no te gusta la metodología de Dios al sacarte de tu zona de confort, pero piensa una vez más en la forma en que José superó todas y cada una de sus duras pruebas</p>
        </div>
      </div>
      <div id="text-container">
        <div className="text-section ">
          <p>Él logró salir aprobado de:</p>
          <ul className="text-section_list">
            <li><p>La casa del odio, donde sus hermanos desataron toda su ira contra él. </p></li>
            <li><p>El pozo de la desesperación, donde se jugó la vida y su destino.</p></li>
            <li><p>El camino de la esclavitud, conducido con herrumbrados grilletes en pies, manos y cuello cuando antes tenía un abrigo de muchos colores.</p></li>
            <li><p>La casa de la tentación, de donde salió desnudo, sin sus ropas, pero vestido de fidelidad y valores que honran al Dios de los cielos.</p></li>
            <li><p>La cárcel de la injusticia, donde se dedicó a desarrollar su don en vez de patalear, acusar y señalar a los ingratos que lo habían abandonado allí.</p></li>
            <li><p>La prolongación del olvido, cuando otros se olvidaron de él, para luego acordarse en el momento justo y preciso para completar el plan eterno de Dios con su vida.</p></li>
            <li><p>La inminente venganza, pero en vez de eso: el perdón.</p></li>
          </ul>
          <p>La venganza no es nuestra, pues el báculo de juicio pertenece a los poderosos. Al estar en una posición de autoridad pudo tomar venganza del daño que sus hermanos le infringieron, sin embargo, interpretó que Dios había obrado en lo sucedido y decidió perdonarlos y amarlos una vez más. </p>
          <p>Al final de toda esa trama de sufrimiento, rechazo y dolor, recibió de mano de Dios el puesto de honor que le fue prometido, siendo promovido para ser el segundo después de Faraón, cumpliendo así el sueño que recibió de joven. José era, realmente, el poder que gobernaba en Egipto.</p>
        </div>
      </div>
      <div id="line-section_one">
        <div className="background-line line7"></div>
        <div className="background-line line8"></div>
      </div>
      <div className="text-section text_two">
        <p><span className="first-word">EL</span> salmo 105 es una comprobación final de que Dios sacó a José de su zona de confort para mostrarle un propósito mayor, poniéndolo en el centro de su voluntad. Allí le dio poder, conocimiento, control, propósito, enfoque y, sobre todo, probó lo más íntimo de su ser al darle un corazón recto lleno de perdón y entendimiento. Un corazón como el de José es el que
        debiéramos anhelar en nuestra vida. Eso solo es posible en Cristo Jesús. Veamos lo que dice ese salmo.</p>
        <p>“Envió un varón delante de ellos; a José, que fue vendido por siervo. Afligieron sus pies con grillos; en cárcel fue puesta su persona. Hasta la hora que se cumplió su palabra, el dicho de Jehová le probó. Envió el rey, y le soltó; el señor de los pueblos, y le dejó ir libre. Lo puso por señor de su casa, y por gobernador de todas sus posesiones, para que reprimiera a sus grandes como él quisiese, y a sus ancianos enseñara sabiduría” (Salmos 105:17-22).</p>
        <p>Para concluir este capítulo, amado lector, quiero animarte a que separes tiempo, agarres tu Biblia y un cuaderno, y te dediques a la tarea de continuar investigando otros aspectos de la historia de José. Además, me encantaría que los predicadores de la palabra, pastores, maestros y ministros del Señor en general, puedan usar estas notas para bendecir a la Iglesia, esposa del Cordero.</p>
      </div>
      <div className="svg_bottom">
        <ShapeTwo color={"#2a2e30"} width={50} height={50}/>
      </div>
      <CheckoutSection />
      <Footer />
    </div> 
  );
};

export default ExcerptPage;
