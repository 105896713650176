import React, { useEffect, useRef, useState } from 'react';

const FadeIn = ({ children, threshold = 0.1, delay = 0, duration = 1000 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();
  
  useEffect(() => {
    const currentRef = domRef.current;
    
    const observer = new IntersectionObserver(entries => {
      // When the component is visible
      if (entries[0].isIntersecting) {
        // Set a timeout to allow us to apply the fade-in class after a delay
        setTimeout(() => {
          setIsVisible(true);
        }, delay);
        // Stop observing once we've seen it
        observer.unobserve(currentRef);
      }
    }, { 
      threshold: threshold // Explicitly use the threshold prop here
    });
    
    // Start observing the component
    if (currentRef) {
      observer.observe(currentRef);
    }
    
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [threshold, delay]); // Make sure threshold is in the dependency array
  
  // For debugging
  console.log(`FadeIn component with threshold: ${threshold}`);
  
  return (
    <div 
      ref={domRef}
      style={{
        opacity: isVisible ? 1 : 0,
        transition: `opacity ${duration}ms ease`,
        willChange: 'opacity' // Performance optimization
      }}
    >
      {children}
    </div>
  );
};

export default FadeIn;