import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../index.css";
import AboutSection from "./AboutSection";
import LandingpageSection from "./LandingpageSection";
import LandingBookSection from "./LandingBookSection";
import ShapeTwo from "./icons/ShapeTwoIcon";
import Footer from "../components/Footer"
import Navbar from './Navbar';

const HomePage = () => {

  return (
    <div>
      <Navbar />
      <LandingpageSection />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#d4edf4" fill-opacity="1" d="M0,224L80,192C160,160,320,96,480,106.7C640,117,800,203,960,218.7C1120,235,1280,181,1360,154.7L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
      <LandingBookSection />
      <AboutSection />
      <div className="svg_bottom">
        <ShapeTwo color={"#2a2e30"} width={50} height={50}/>
      </div>
      <Footer />
    </div>  
  );
};

export default HomePage;