import React from 'react';

const EnhancedPictureImage = ({
  sources = [],  // Array of source objects with srcSet, media, type
  fallbackSrc,   // Required fallback image
  alt,           // Required alt text
  className,     // Optional CSS class
  width,         // Optional width
  height,        // Optional height
  loading = "lazy", // "lazy" or "eager"
  fetchPriority = "auto", // "high", "low", or "auto"
}) => {
  return (
    <picture>
      {sources.map((source, index) => (
        <source
          key={index}
          srcSet={source.srcSet}
          media={source.media}
          type={source.type}
        />
      ))}
      
      <img
        src={fallbackSrc}
        alt={alt}
        className={className}
        width={width}
        height={height}
        loading={loading}
        fetchpriority={fetchPriority}
      />
    </picture>
  );
};

export default EnhancedPictureImage;