import React, { useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import "./index.css";
import NotFound from "./components/Notfound";
import HomePage from "./components/HomePage";
import BookPage from "./components/BookPage";
import ExcerptPage from "./components/ExcerptPage";


const router = createBrowserRouter([
  {
  path:'/',
  element:<HomePage />,
  errorElement: <NotFound />
  },
  {
  path:'/viaje',
  element:<BookPage />,
  errorElement: <NotFound />
  },
  {
  path:'/libro',
  element:<ExcerptPage />,
  errorElement: <NotFound />
  },
]) 

const App = () => {


  return (
    <div>
      <RouterProvider router={router} />
    </div>  
  );
};


export default App;