import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import '../index.css';
import BurgerMenu from './icons/HamburgerMenu';

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const location = useLocation(); // Get the current URL location

    useEffect(() => {
        const handleScroll = () => {
            const landingPage = document.getElementById('landingpage');
            const landingPageHeight = landingPage ? landingPage.offsetHeight : 0;
            const currentScrollY = window.scrollY;

            if (currentScrollY > landingPageHeight) {
                if (currentScrollY < lastScrollY) {
                    setShowNavbar(true);
                } else {
                    setShowNavbar(false);
                }
            } else {
                setShowNavbar(false);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <nav
                id="navbar"
                className={showNavbar ? '' : 'hidden'}
            >
                <div className="navbar-container">
                    <div className="navbar-name">
                        <h1>
                            ÁLVARO PORRAS
                        </h1>
                        <div className="navbar-links">
                            {(location.pathname === '/viaje' || location.pathname === '/libro') && (
                               <Link to="/" className="navbar-link link3">
                                   <p>Página principal</p>
                               </Link>
                           )}
                            {location.pathname !== '/viaje' && (
                                <Link
                                    to="/viaje"
                                    className="navbar-link link1"
                                >
                                    <p>Acerca<span> del libro</span></p>
                                </Link>
                            )}
                            {location.pathname !== '/libro' && (
                                <Link
                                    to="/libro"
                                    className="navbar-link link2"
                                >
                                    <p>Leer<span> una porción del libro</span></p>
                                </Link>
                            )}
                        </div>
                    </div>
                    <BurgerMenu onClick={toggleMenu} />
                </div>
            </nav>

            {menuOpen && (
                <div className="fullscreen-menu">
                    <h2 className="menu-item">MENÚ</h2>
                    <h2 className="menu-item">
                        <a href="/">Página principal</a>
                    </h2>
                    <h2 className="menu-item">
                        <a href="/viaje">Acerca del libro</a>
                    </h2>
                    <h2 className="menu-item">
                        <a href="/libro">Leer una porcion gratuita</a>
                    </h2>
                </div>
            )}
        </>
    );
};

export default Navbar;