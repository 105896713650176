import React from "react";
import EnhancedPictureImage from "./EnhancedPictureImage"
import FadeIn from "../components/Fadein"

const LandingBookSection = () => {
  return (
    <div id="LandingBook">
          <div className="LandingBook_containers">
            <div className="LandingBook_left-container">
                <FadeIn delay={400} threshold={0.1}>
                <h1>VIAJ<span className="small-text">E</span> AL C<span className="small-text">E</span>NT<span className="small-text">R</span>Ø D<span className="small-text">E</span> LA V<span className="small-text">O</span>LU<span className="small-text">N</span>TAD DE DIOS</h1>
                <p>Este libro te dará herramientas y perspectivas para guiar tu camino espiritual hacia tu destino celestial</p>
                <a className="review_calltoaction-button button" href="/viaje">Acerca </a>
                </FadeIn>
            </div>
            <div className="LandingBook_right-container">
            <FadeIn delay={300} threshold={0.1}>
            <EnhancedPictureImage
              sources={[
                {
                  srcSet: "/images/Tableimg_xlarge.webp",
                  media: "(min-width: 1200px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Tableimg_medium.webp",
                  media: "(min-width: 800px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Tableimg_small.webp",
                  type: "image/webp"
                }
              ]}
              fallbackSrc="/images/product.jpg"
              alt="Product showcase"
              className="LandingBook_right-container-image"
              loading="lazy"
              fetchPriority="high"
              />
              </FadeIn>
            </div>
        </div>
    </div>
  );
};

export default LandingBookSection;