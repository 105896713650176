import React from "react";
import EnhancedPictureImage from "./EnhancedPictureImage"
import FadeIn from "../components/Fadein"

const AboutSection = () => {
  return (
    <div id="about">
      <div className="about_containers">
        <div className="about_left-container">
          <FadeIn delay={300} threshold={0.1}>
          <EnhancedPictureImage
              sources={[
                {
                  srcSet: "/images/Aboutmeimg_xlarge.webp",
                  media: "(min-width: 1200px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Aboutmeimg_medium.webp",
                  media: "(min-width: 800px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Aboutmeimg_small.webp",
                  type: "image/webp"
                }
              ]}
              fallbackSrc="/images/product.jpg"
              alt="Book on table"
              className="about_left-container-image"
              loading="lazy"
              fetchPriority="high"
              />
            </FadeIn>
        </div>
        <div className="about_right-container">
          <FadeIn delay={400} threshold={0.1}>
          <h2>CONOZCA</h2>
          <h2>AL PASTOR</h2>
          <h2>ÁLVARO PORRAS</h2>
          <p>Álvaro Porras es pastor y escritor, y ha servido con el Ejército de Salvación en los Estados Unidos durante 21 años, dedicando su vida al ministerio junto a su esposa Danitza y sus hijos. Apasionado por la fe y el servicio, comparte sus experiencias y enseñanzas bíblicas a través de la predicación y la escritura, con el propósito de inspirar a otros en su camino espiritual. Además de su labor pastoral, disfruta de la carpintería, el arte y las conversaciones significativas que fomentan el crecimiento y la conexión. Continúa abrazando el llamado de Dios con humildad, buscando animar a otros a través de sus palabras y su ministerio.</p>
          </FadeIn>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;