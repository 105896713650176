import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../index.css";
import ReviewSection from "./ReviewSection";
import BooktitleSection from "./BooktitleSection";
import Footer from "../components/Footer"
import CheckoutSection from "./CheckoutSection";
import Navbar from './Navbar';



const BookPage = () => {

  return (
    <div>
        <Navbar />
        <BooktitleSection />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#2a2e30" fill-opacity="1" d="M0,64L80,90.7C160,117,320,171,480,170.7C640,171,800,117,960,106.7C1120,96,1280,128,1360,144L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
        <ReviewSection />
        <CheckoutSection />
        <Footer />
    </div>  
  );
};

export default BookPage;


