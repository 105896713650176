import React from "react";
import ImageBackgroundSVG from "../components/icons/ImageBackgroundSVG"
import EnhancedPictureImage from "./EnhancedPictureImage"
import FadeIn from "../components/Fadein"


const LandingpageSection = () => {
  return (
    <div id="landingpage">
        <FadeIn delay={50} threshold={0.1}>
        <div className="landingpage-container">
          <div className="left-container">
            <ImageBackgroundSVG />
            <EnhancedPictureImage
              sources={[
                {
                  srcSet: "/images/Landingimg_xlarge.webp",
                  media: "(min-width: 1200px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Landingimg_medium.webp",
                  media: "(min-width: 800px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Landingimg_small.webp",
                  type: "image/webp"
                }
              ]}
                fallbackSrc="/images/product.jpg"
                alt="Landing, portrait picture"
                className="landing-image"
                fetchPriority="high"
            />
          </div>
          <div className="right-container">
            <h1>ÁLVAR<span className="small-text_landing">O</span> P<span className="small-text_landing">O</span>R<span className="small-text_landing">R</span>AS</h1>
            <div>
              <h2>PASTOR</h2>
              <h2>AUTØR</h2>
            </div>
          </div>
        </div>
        </FadeIn>
      </div>
  );
};

export default LandingpageSection;


