import React from "react";
import ListSection from "./ListSection";
import EnhancedPictureImage from "./EnhancedPictureImage"

const ReviewSection = () => {
  return (
    <div id="review">
      <h2 className="review_title">
        Una aventura emocionante hacia el centro de la voluntad de Dios
      </h2>
      <div className="review_containers">
        <div className="review_left-container">
          <div className="review_left-container-text">
            <p>
              Hay personajes de la Biblia que algunos llaman “los NO contados”, 
              los cuales constituyen ese grupo de desconocidos que aportaron su 
              grano de arena en la historia bíblica, como el joven del relato de 
              la multiplicación de los panes y los peces, la mujer que tocó el 
              borde del manto de Jesús, etc.; cuyos nombres ni siquiera se señalan 
              en el registro sagrado.
            </p>
            <p>
              Es probable que tú y yo pertenezcamos también a ese grupo. Sin embargo, 
              Dios los tiene bien identificados y planea hacer maravillas con ellos 
              en este tiempo del fin. Por eso, querido lector, espero que tu viaje 
              al centro de la voluntad de Dios, al igual que el mío, apenas empiece. 
              Las ideas vertidas en este libro tienen el expreso fin de ayudarnos a 
              comprender cómo se llenaron esos espacios en blanco en las vidas de 
              esos hombres y mujeres de fe que aparecen en la sagrada Escritura y, 
              así mismo, ayudarte a llenar los tuyos.
            </p>
            <p>
              ¿Quién soy?, ¿cómo entender mi proceso de vida?, ¿hacia dónde me dirijo?, 
              ¿cuáles son mis expectativas?, ¿por qué soy como soy? y un largo etcétera 
              de planteamientos similares, son preguntas existenciales que muchos de 
              nosotros nos hemos formulado. Parece ser que la mayoría de ellas tienen 
              que ver con un propósito o un entendimiento que nos conduzca a las respuestas. 
              Da la impresión de que todos estamos en un viaje y que somos compañeros en 
              esta búsqueda del conocimiento que ha de iluminar nuestro sendero.
            </p>
            <p>
              Viaje al centro de la voluntad de Dios te hará conocer por qué existes, 
              para qué estás en esta tierra, a dónde te diriges, entre otras cosas más. 
              La lectura de esta obra te ayudará en tu peregrinaje espiritual.
            </p>
            <p>- NAHUM SAEZ ★ EDITOR</p>
          </div>
        </div>
        <div className="review_right-container">
          <EnhancedPictureImage
            sources={[
              {
                srcSet: "/images/Airportimg_xlarge.webp",
                media: "(min-width: 1200px)",
                type: "image/webp"
              },
              {
                srcSet: "/images/Airportimg_medium.webp",
                media: "(min-width: 800px)",
                type: "image/webp"
              },
              {
                srcSet: "/images/Airportimg_small.webp",
                type: "image/webp"
              }
            ]}
              fallbackSrc="/images/product.jpg"
              alt="Landing, portrait picture"
              className="review_right-container-image"
              loading="lazy"
              fetchPriority="high"
            />
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
        <path fill="#345c72" d="M0,128L80,149.3C160,171,320,213,480,197.3C640,181,800,107,960,80C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z" />
      </svg>
        <ListSection />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
        <path fill="#345c72" d="M0,0L1440,0L1440,96L1360,85.3C1280,75,1120,53,960,80C800,107,640,181,480,197.3C320,213,160,171,80,149.3L0,128Z" />
        <path fill="#d4edf4" d="M0,128L80,149.3C160,171,320,213,480,197.3C640,181,800,107,960,80C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z" />
      </svg>
      <div className="review_calltoaction">
        <h2>Dios tiene un plan trazado para tí, incluso si aún no lo comprendes del todo</h2>
        <h2>¿Estás listo para dar el siguiente paso?</h2>
        <a className="review_calltoaction-button button" href="#checkout">
          ORDENAR
        </a>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319"><path fill="#d4edf4" fill-opacity="1" d="M0,128L60,128C120,128,240,128,360,144C480,160,600,192,720,181.3C840,171,960,117,1080,101.3C1200,85,1320,107,1380,117.3L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
      <div className="belowreview_calltoaction">
        <EnhancedPictureImage
              sources={[
                {
                  srcSet: "/images/Coffeeimg_xlarge.webp",
                  media: "(min-width: 1200px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Coffeeimg_medium.webp",
                  media: "(min-width: 800px)",
                  type: "image/webp"
                },
                {
                  srcSet: "/images/Coffeeimg_small.webp",
                  type: "image/webp"
                }
              ]}
                fallbackSrc="/images/product.jpg"
                alt="Landing, portrait picture"
                className="review_bottomimage"
                loading="lazy"
                fetchPriority="high"
            />        

        <h2>Dios nos desafía a salir de nuestra zona de confort para llevarnos en un viaje emocionante</h2>
        <a className="belowreview_calltoaction-button button" href="/libro">
          LEER UNA PORCION DEL LIBRO
        </a>
      </div>
    </div>
  );
};

export default ReviewSection;

