import React from "react";
import ReactDOM from "react-dom";
import "../index.css"
import "../excerpt.css";
import ShapeOne from "../components/icons/ShapeOneIcon"
import FadeIn from "../components/Fadein"

const ExcerptLandingPage = () => {
  return (
    <div>
      <div className="container">
        <div className="background"></div>
        <div className="background-line line1"></div>
        <div className="background-line line2"></div>
        <div className="quote-box">
          <h2>
            “Sus sueños, cual zigzag de la eternidad, fueron torbellino de la
            providencia, escalones que allanaron la cumbre donde coronaron, no a
            José, sino al amor y al perdón”. — Álvaro Porras
          </h2>
        </div >
        <div className="quote-box_two"><h2>José,</h2></div>
        <div className="quote-box_three"><h2>Intérprete de sueños</h2></div>        
        <div className="quote-box_four"><h1>Por Álvaro Porras</h1></div>
        <div className="background-line line3"></div>
        <div className="quote-box_five"><p>Capítulo 11</p></div >
        <FadeIn delay={600} threshold={0.9}>
        <img className="image-chains" src="/images/Chains.webp"></img>
        </FadeIn>
        <FadeIn delay={600} threshold={0.2}>
        <img className="image-bark" src="/images/bark.png"></img>
        </FadeIn>
        <FadeIn delay={600} threshold={0.2}>
        <img className="image-compass" src="/images/compass.png"></img>
        </FadeIn>
        <FadeIn delay={600} threshold={0.6}>
        <img className="image-note" src="/images/note.png"></img>
        </FadeIn >
        <FadeIn delay={600} threshold={0.2}>
        <ShapeOne color={"#345c72"} top={"var(--shape-top)"} right={"var(--shape-right)"} width={"var(--shape-width)"} height={"var(--shape-height)"} transform={"rotate(15deg)"}/>
        </FadeIn>
      </div>
    </div> 
  );
};

export default ExcerptLandingPage;
