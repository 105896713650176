import React, { useState } from "react";
import PayhipCheckoutButton from './PayhipCheckoutButton';import EnhancedPictureImage from "./EnhancedPictureImage"


const CheckoutSection = () => {
  const [selectedOption, setSelectedOption] = useState("ebook"); 
  
  const productOptions = {
    ebook: {
      title: "VIAJE AL CENTRO DE LA VOLUNTAD DE DIOS - eBook",
      price: "$11.99",
      productId: "7b9Jo"
    },
    hardcover: {
      title: "VIAJE AL CENTRO DE LA VOLUNTAD DE DIOS",
      price: "$21.99", 
      productId: "MkHcQ"
    }
  };
  
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  
  const currentProduct = productOptions[selectedOption];

  return (
    <div id="checkout">
        <div className="checkout_containers">
            <div className="checkout_left-container">
                <div className="booktitle-section_image-container"></div>
                <EnhancedPictureImage
                  sources={[
                    {
                      srcSet: "/images/Checkoutimg_xlarge.webp",
                      media: "(min-width: 1200px)",
                      type: "image/webp"
                    },
                    {
                      srcSet: "/images/Checkoutimg_medium.webp",
                      media: "(min-width: 800px)",
                      type: "image/webp"
                    },
                    {
                      srcSet: "/images/Checkoutimg_small.webp",
                      type: "image/webp"
                    }
                  ]}
                    fallbackSrc="/images/product.jpg"
                    alt="Book on table"
                    className="checkout_right-container-image"
                    loading="lazy"
                    fetchPriority="high"
                />
            </div>
            <div className="checkout_right-container">
                <h2>{currentProduct.title}</h2>
                <h2>{currentProduct.price}</h2>

                <div className="product-format-selector">
                  <label>
                    <div className="option-container">
                      <input 
                        type="radio" 
                        name="productFormat" 
                        value="ebook" 
                        checked={selectedOption === "ebook"} 
                        onChange={handleOptionChange}
                      />
                      <div className="option-label">
                        <p>Libro Digital</p>
                        <p>$11.99</p>
                      </div>
                    </div>
                  </label>
                  <label>
                    <div className="option-container">
                      <input 
                        type="radio" 
                        name="productFormat" 
                        value="hardcover" 
                        checked={selectedOption === "hardcover"} 
                        onChange={handleOptionChange}
                      />
                      <div className="option-label">
                        <p>Libro físico - preorder</p>
                        <p>$21.99</p>
                      </div>
                    </div>
                  </label>
                </div>
                
                <p>¿Sientes en lo más profundo de tu corazón que fuiste creado para algo más? ¿Qué pasaría si el camino que recorres, con sus desafíos y sorpresas, fuera parte de un viaje divino hacia el centro de Su voluntad?</p>
                <PayhipCheckoutButton 
                  productId={currentProduct.productId}
                  buttonText="Comprar YA" 
                  customMessage="¡Muchas gracias por su compra!"
                  className="checkout-button button"
                />
                <div className="checkout_right-container-bottom">
                    <div>
                        {selectedOption === "ebook" ? (
                          <p>El eBook te llegará por correo electrónico. Si no ha llegado en 5 minutos, revisa la carpeta de spam en tu correo.</p>
                        ) : (
                          <p>En este momento los libros se estan imprimiendo. El libro físico será enviado a la dirección proporcionada cuando esté listo. El libro digital esta disponible hoy.</p>
                          // <p>El libro físico será enviado a la dirección proporcionada. Por favor permita tres días hábiles para procesar su pedido.</p>
                        )}
                        <p>VISA MASTERCARD AMEX PAYPAL, ETC </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default CheckoutSection;