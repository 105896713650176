import * as React from "react";

const ShapeOne = ({ top = "5%", right = "5%", width = 100, height = 100 , color, transform, position ="absolute" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1"
    style={{
      position: position,
      top: top,
      right: right,
      width: width,
      height: height,
      transform: transform
    }}
    viewBox="0 0 375 375"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M275.555 229.688c17.715 10.335-.61 6.785 20.46 15.898l9.622 5.223c-21.188 6.261-33.918 5.093-30.082-21.121M245.41 212.68c13.067-10.625.91-3.61 17.64 1.808 3.337-8.207-2.347-7.504 5.012-13.187l8.047 5.73c.274.246.903 1 1.434 1.414 4.586-6.754.426-5.578 8.695-7.625 6.883 6.032 1.621-1.379 6.293 10.024 8.293-7.535-.125-6.766 13.985-2.242l-2.407 9.332c9.27 4.132-8.59-2.942 4.188 1.148.289.094 1.273.406 1.566.508l-1.703 14.336c-.707 1.496 5.926 4.61-10.324 5.844-.211-.176-.402-.93-.438-.696l-2.64-2.258c-6.293-3.32 3.254 6.004-7.293-3.816 1.562-17.5-.313.2 4.504-13.191-47.946 23.464-11.723 34.886-2.528 44.488l6.914-.922c4.524-8.516-6.96-6.125 7.97-7.215 2.675 8.805.75 10.8 11.12 9.387-11.363-20.125 19.563-31.152-3.172-56.3-12.652-14-38.21-17.669-56.84-4.923 22.43-17.297 20.645-9.851 28.82-16.672-37.913-5.468-28.628 1.328-39.472 12.157l-35.191 14.418c-.41-.711-1.578.195-2.278.53 16.688 7.184 22.57 1.997 38.098-2.077m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M104.902 229.91c4.227 24.559-8.613 26.094-28.816 20.758 3.242-2.488 1.25-1.86 6.926-3.95 29.27-10.784 4.386-4.003 21.89-16.808m10.207 8.703 1.207-4.367c-12.273-2.234-8.242-6.91-17.972-10.918L88.23 221.34c7.696 10.41 2.407-3.664 6.227 11.754-7.629 5.437-6.16 2.738-6.543 2.926l-11.7 4.808-4.089-8.41c-5.79-12.613-3.945 10.242-4.387-11.594 8.965-5.492-4.914 7.356 5.535-2.531 6.47-6.121-3.16 6.984 1.446-3.055.34-.75-3.574-6.097 8.308-7.535l3.25.406c6.024.207-2.695 3.297 2.883.172 8.363-4.68-4.144 2.805 2.848-6.664 14.867-.683 4.914-2.332 11.597 6.39l10.946-7.37c-1.004-1.016-.184-1.606-5.977-3.563-8.715-2.941 5.473.133-4.883-1.12-5.976-.727-7.75.316-11.695 1.558-17.14 3.398-27.144 11.3-30.242 27.761-3.676 19.485 18.351 32.82 3.273 40.395 14.676 1.352 5.95-1.211 15.391-8.66 18.176 11.156 28.309-3.27 34.691-18.395m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M233.617 130.926c.946.187 9.477.605 14.969-2.586 6.883-3.996 5.105-5.715 11.34-12.297 2.699 19.578.476 13.531-6.762 26.8-14.078 1.04-20.21 1.9-19.547-11.917m-37.922-1.953c3.262 6.91 2.774 2.804-2.617 5.02l.754 9.073c8.938.54 1.715-5.8 8.055 3.871l.883-10.187c.675-6.93.703-15.445 4.89-21.012 10.95-14.543-4.746 2.746 12.04-1.566l-.012-3.664c.878-5.758-4.958 1.097 2.035-6.914.293-.336.953-1.024 1.32-1.29 4.797-3.46 7.574-.445 12.383.15 9.937 1.218 5.664-3.27 9.883-.532 10.984 7.121 2.687 2.976 3.66 13.5.066.707 1.398 5.273 1.086 8.328l-23.094 1.414c2.488 32.125 31.465 28.766 38.738 1.445 9.946-7.757 20.262-4.12 10.688-10.097-22.871-5.61-6.961-28.63-51.688-23.028-14.847 8.188-19.703 19.082-29.004 35.489M252.71 231.262c8.161 14 2.294-8.418 7.497 7.523-10.055 3.762-18.523.336-31.945 1.012l-15.903 5.973c5.344-.426 3.73-1.485 3.73 4.32 3.825-1.09 9.688-.594 10.329-.61 4.84-.105-3.227 1.22 3.902-.273l6.215-1.785a23 23 0 0 1 1.8.016c10.58.761 15.095 4.519 17.231 13.078 1.196 4.793 7.196 7.507-7.89 12.09-1.785-12.56 5.789-5.516-4.926-12.536-5.96 4.336-10.031 5.461-7.48 14.625 2.96 10.63 3.414 4.825 13.296 10.848 30.504-3.207 25.59-28.742 12.41-33.797l2.481-6.035c3.684-11.285 3.598-8.203 1.797-15.64-7.645-2.954-5.219-4.118-12.547 1.183Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M120.828 115.98c9.176 7.797 11.11 17.883 27.074 14.52l-4.984 12.605c-16.86 3.317-16.969-.48-22.09-27.125m3.086 29.305 4.426 1.844 7.773.984c12.653-4.183 16.078-7.793 17.63-22.949-.59-.336-1.634-1.406-1.86-.816l-8.352-1.98c-.215.023-.918.28-1.148.323l-11.64 1.34c-3.977-17.668.683-4.976 1.163-11.004.91-11.453-4.36 1.008.235-11.656 18.277-6.308 6.582.02 9.601.371l4.207.36c6.406-.832 14.856-10.985 15.828 10.253.016.352-.222 1.29-.007 1.88 14.488 3.472-1.7-2.372 11.042-3.872-3.105-7.504-8.351-12.273-16.546-17.031-43.422-4.379-29.227 12.367-47.41 22.621l-8.415 4.082c1.215.403 22.164 1.977 23.47 25.25Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m116.316 234.242-1.207 4.371c7.032 16.961 3.368 6.075-.5 16.094-4.707 12.203-3.695 6.695-2.664 21.184 6.688 6.367 14.637 15.328 25.664 4.57l-9.21.812.335-8.863-2.816-11.941c.766-3-3.395-11.676 16.527-12.817l3.801.196c5.887.707 2.234 4.062 10.754-.47 3.285-3.73-4.805-6.632 5.59-1.804-9.809-11.707-25.965-1.316-42.793-5.898 5.183-11.317.023-1.903 10.59-9.762.379-.281 1.16-.758 1.527-1.062l2.707-2.665c2.395-2.734 1.465.458 2.774-5.05-13.086-.809-2.032-1.516-11.399 4.347-1.613 1.012-4.437 3.168-5.351 3.95-5.536 4.718-.336-3.145-4.336 4.808Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M166.441 285.996c1.973-1.5 2.325-16.375 13.926-4.293-3.644 9.074-1.719 6.91-13.926 4.293m10.235-25.058c5.64.316 4.87-6.387 5.476 1.132l.094 4.196c.879 12.144-5.785 4.5-21.094 9.64 1.54 16.274 8.149 12.621 12.832 21.664 1.414 2.739 2.493 8.63 5.055 12.993l9.363 11.238c8.672-.485 1.11.578 3.227-3.442l4.73-4.78c-23.363-.005-2.668.722-10.91-4.028l1.813-17.176.43-4.441 4.335-6.23c-10.773-3.208-3.172 2.917-5.304-8.09-.743-10.235-.633-10.274-7.664-18.063-1.043-1.153-7.45-6.996-7.48-7.012-7.45-4.566 1.894-1.687-8.981-2.965-10.399-4.828-2.309-1.926-5.594 1.805l9.008 1.93-3.82 6.863c-.204.512-.415 1.726-.704 2.562 6.196-2.609 6.282-10.816 15.188 2.204m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M214.266 281.703c-1.328 2.23 1.847 5.379-6.418 5.899-10.344.652-3.868-.575-7.57-5.899 1.34-.86-3.962-10.992 13.988 0m-27.54-8.09c2.13 11.008-5.468 4.883 5.305 8.09l-4.34 6.23c6.84 2 3.207-3.25 6.602 4.805 2.457 11.54 6.5 16.407-8.84 16.813 8.242 4.75-12.453 4.023 10.906 4.027l-4.726 4.781c4.52-2.402 3.078-2.234 7.402-1.882l7.68-19.329c.277-.566.633-1.336.945-1.847 6.453-10.485 10.352-3.574 12.13-19.395-4.595-1.293-4.345 1.098-17.02-3.695l-4.258-2.102c7.676-10.925 3.64-12.832 15.754-17.449l1.828-2.566c0-5.805 1.61-4.75-3.735-4.32-5.996 6.93-8.273 6.675-12.859 12.992-4.32 5.96-1.836 4.957-5.32 9.304-5.23 6.532.336 2.711-7.453 5.547Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m338.496 157.895-2.96-6.86-6.99-2.676-22.581 34.168c16.066-4.332 26.637-7.129 32.531-24.632m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M127.035 201.953c6.293.89 5.543-4.797 5.543 1.496 4.969 0 3.254-.664.649 4.246 6.039 4.387 12.171 8.457 20.402 9.559l18.101-5.402c-13.789 3.12-24.578-3.032-31.214-7.489-8.383-5.633-5.368-5.32-10.149-10.363-5.637-5.95-3.387-2.977-11.304-7.73l-21.32 3.476c18.206 6.11 12.98-.23 29.292 12.211Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m156.266 93.332 5.066-1.394c-7.328-6.122-10.2-9.762-20.46-12.88-13.497-4.109-19.017-4.765-19.923 8.426 14.828 10.371 17.95-4.226 35.32 5.848Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M201.09 315.852c.695 5.507-4.598 10.843 3.996 8.894 10.789 1.422 8.363-1.21 20.082-.55 13.613.761 10.45 6.437 22.078-.52l.89-1.98-2.784-7.278c-13.352-.66-22.684 2.633-31.586 8.719l-4.563-5.297c-5.754-3.797-.348-1.457-8.113-1.988m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M60.984 191.324c-8.629 4.512-9.816 5.633-11.027 16.715 28.543.266 8.66-15.844 37.461-17.61-5.027-5.12 1.004-2.199-7.184-4.199-2.492-.609-5.324-.355-7.875.086-4.925.86-7.23 2.75-11.375 5.004Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M132.59 325.168c4.047.621 37.992-2.39 35.894 1.008l7.578-1.453c0-7.657 1.57-5.805-6.183-3.414-13.953-1.836-11.316-9.07-34.52-6.918l-2.77 10.78Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m57.227 174.102 14.074 6.98-7.352-7.445c-4.316-12.074-7.047-26.157-18.992-22.926-5.992 13.215 3.555 17.504 12.27 23.387Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M294.352 186.145c10.277 6.23 11.296 5.386 19.027 13.585 4.36 4.633 6.582 8.438 15.473 6.602 1.414-10.348-.293-12.96-11.051-17.059-7.715-2.941-14.946-3.265-23.45-3.128m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M100.727 282.742c10.41.516 8.421 3.395 8.421-5.914l-8.695-.816c-4.625-10.25-4.812-15.348-17.137-14.645-6.078 12.93 7.086 15.383 17.41 21.38Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M236.914 80.957 224.7 93.484c8.473-9.207 19.696.762 33.7-4.918 5.246-16.254-9.997-11.187-21.485-7.609m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m186.86 142.973-1.15-8.672c-3.41-8.067-1.483-6.586-4.112-14.192-6.364-18.394-3.258-7.894-8.782-9.75l5.77 18.72-11.988-3.665c2.96 12.688 4.882 11.582 12.777 6.871l-.066 15.563c4.058-7.274.535-4.485 7.546-4.875Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m182.305 345.406 15.156-4.16-3.988-6.582c-11.52.445-2.75-4.668-10.352 4.352-4.414-7.493-1.676-8.43-15.723-7.637 1.684 10.625 2.942 9.543 14.907 14.027m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m128.734 272.41-.336 8.863 9.211-.812c5.88-.492 3.414 2.406 6.75-.79 2.38-11.316 4.688-15.405-8.867-18.116-5.62 10.984 7.246 7.855-6.762 10.855Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m296.355 263.375-6.914.922c-2.738 2.164.774-1.25-2.98 3.16l-13.969 15.086c15.926-1.8 39.195-16.512 23.863-19.168M259.246 302.29c10.399-4.325 2.68 1.093 6.414-6.954 3.66 3.152 1.238-.43 3.774 4.726l9.554-1.738c-.972-4.402-.633-7.316-5.66-9.86-17.797-9.01-14.598 2.38-15.512 3.74 7.668 2.655 1.754.53 1.426 10.085Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M145.848 74.645c-7.985-32.922.562-20.168-10.356-25.81-4.972 7.161-6.426.61-1.96 13.849 4.023 11.93 4.155 8.433 12.316 11.96m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M234.629 74.297c7.328-3.09 7.062.68 12.008-9.277 3.633-7.332 2.43-7.258 1.457-15.051-12.719-.836-8.364 9.226-13.469 24.328Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m179.367 204.402.887-10.765c-8.012-7.07-20.598-18.77-22.824 5.52 16.11 2.331 10.175-3.024 19.406-7.4 3.926 6.927 3.793 1.13 2.531 12.645m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M203.973 191.465c10.816 4.828 4.398 9.11 19.476 7.687-2.008-26.527-24.3-11.675-23.125 5.75Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M133.227 207.695c2.605-4.91 4.316-4.246-.649-4.246 0-6.293.75-.605-5.543-1.496l-12.488-1.316c3.492 5.16 1.031 8.707.668 14.375 6.312-.492 4.281 1.02 12.914-5.61 2.766 1.75 2.559 1.32 4.16 4.2l.938-5.91Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M164.102 174.43c10.765 2.238 10.328 1.351 12.808 10.367 3.61-4.672 4.469.738 3.461-8.602-.297-2.765-6.285-14.011-13.445-11.047-7.305 6.243-2.2-.293-2.82 9.282Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m199.234 52.414 1.641 6.691c11.152-17.015 14.2-9.964 17.18-16.882 4.879-11.313-.508-5.137-1.91-7.078-6.598-.372-9.239 8.468-14.165 14.562-.296.371-1.101 1.098-1.351 1.383-.246.285-.926.883-1.39 1.324Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m192.906 227.39-4.441-.007-2.957 19.043 8.437 4.05c4.078-8.581 1.14-10.292-1.043-23.081Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M163.406 236.535c13.969-1.515 15.219-5.187 18.656-20.332l-9.132 7.281c-10.621 7.496-6.785.317-9.524 13.051m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m197.46 341.25 1.438 8.156c11.032-11.402 12.438-2.531 17.395-16.125l-10.258-2.484c-2.71 7.086-6.426 9.043-8.574 10.45ZM177.781 58.395c4.633-2.614 2.305 3.687 3.973-5.907l-16.258-18.492c-11.7 14.8 7.848 7.106 12.285 24.399m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M104.219 301.36c8.687-3.07 4.781-1.051 9.117-5.813-1.441-7.262-2.582-5.52 4.812-3.692 12.844-1-.312 1.184 5.579-6.226l-14.309 1.687-8.188 6.23 2.989 7.81Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m185.2 46.91-3.614.602.168 4.98c15.629 4.082-4.352-.617 8.914-.89 13.727-.29-7.695 4.625 8.566.812l-.05-5.227-1.73-.355c-5.306-4.71-4.18 6.605-3.993-8.281l-5.516-.809-2.75 9.168Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m196.96 205.727-.058-10.196c-7.468-4.844.29-.93-10.492-4.074l.64 18.262c6.63-2.176 5.141-.766 9.91-3.996ZM186 65.188l-.355 14.433c13.632.063 10.355-8.262 7.894-18.527-5.77 2.172-3.879.754-7.543 4.097Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m187.957 172.383 5.57.347c.434-3.039 2.168-11.042 2.309-13.503l-1.586-9.618-8.402 11.965c.343 2.559 1.601 6.531 2.109 10.809m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M199.313 182.45c7.742-3.31 13.445-5.731 18.484-13.805-.633-.774-1.274-3.208-1.863-2.352-.575.82-7.04-1.64-7.954-1.867Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m187.95 37.742 5.51.813 1.727-17.809-10.394-.367Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M204.11 63.465c.663-.113 9.913-1.067 10.163-1.133 8.356-2.191 5.63.059 9.387-6.738 0 0-.394-15.707-19.547 7.87ZM181.586 47.512l3.613-.602-5.59-23.273-7.742 4.082c4.18 8.226 6.2 9.383 9.719 19.797Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M170.313 61.684c5.386-.407 5.5 1.246 2.902-3.024-2.875-2.289-6.469-6.168-9.653-6.082-11.132.305-4.925-5.98-5.53 8.98Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M216.648 159.543c5.028-4.242 4.532-3.418 2.915-10.578l-10.961-1.875.03 9.297 8.02 3.156Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m217.54 237.316-12.99-15.984c-.445-.281-1.382-.89-1.741-1.117l-3.934-1.738 8.402 17.277Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M172.023 150.305c-11.16-5.348-10.597.277-11.308.949 2.047 3.207-2.3 8.668 6.41 7.61 5.64-.688 4.79-3.298 4.895-8.56Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m197.453 46.832 1.734.355 8.88-17.699-6.508-5.605Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M237.598 172.172c-.075-4.434.918-18.524-8.86-8.766-7.968 7.95 4.934 8.371 8.86 8.766m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m142.95 171.531 10.429 1.653c-.121-8.133 2.437-4.45-3.39-11.825-11.915 4.387-4.094-3.214-7.04 10.172m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M182.305 345.406c4.066 6.32 1.215 3.242 7.011 8.34l-.62-9.215c8.773 4.086 4.98 2.188 7 9.5 3.175-8.07-1.247-.644 3.202-4.625l-1.433-8.156-15.156 4.16Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M320.926 187.531c5.148 1.16 11.887 4.059 9.605-4.578-2.62-9.902-3.133-3.914-5.746-2.746-8.605 3.844-1.328-1.684-3.86 7.324m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M57.828 190.156c2.746-6.918.863 1.07 1.75-9.855l-11.035-.309c3.922 10.18 1.434 5.778 9.285 10.164m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m225.684 235.305 2.578-.348c6.676-1.82 6.77 5.887 6.027-4.95-.648-9.527.035-1.8-7.305-4.077l-1.297 9.375Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m182.246 266.266-.094-4.196c-.605-7.52.164-.816-5.476-1.132-6.653 6.667-3.305.757-4.762 7.55 8.434 1.688 3.688 2.235 10.332-2.222M257.48 170.75c-20.898-2.121-5.593 22.309 0 0m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m204.785 136.746 8.742-1.984c-.597-9.309 3.418-6.657-5.816-8.016-.723.645-2.168.317-2.23 1.871-.055 1.551-.602 7.168-.696 8.13m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M118.148 291.855c-7.394-1.828-6.25-3.566-4.808 3.692 5.86 8.863.129 3.945 7.664 5.98 3.031-9.105 3.93-1.757-2.852-9.675Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m184.793 122.688 11.23 1.25-3.062-10.317c-6.926 3.574-5.082 1.84-8.172 9.067Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m185.71 134.3 1.15 8.673 6.972.097-.754-9.078Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m125.5 171.582-.914 6.863c10.262 1.653 6.043 2.91 9.727-4l-8.622-4.57-.187 1.703Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M271.742 182.816c4.778-10.035 2.617-7.09-6.222-8.402 1.671 13.68-2.13 6.457 6.222 8.402m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M150.113 227.234c-8.894-.222.766-2.937-4.062 2.953 4.449 9.97 5.66 7.051 9.472-.664-4.238-2.882.774-2.132-5.41-2.289m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M248.742 230.367c2.348-1.988 1.563-3.87 3.594-6.64-.586-.766-1.172-3.172-1.734-2.309-.563.867-6.88-1.086-7.832-1.25 2.816 9.527-.344 3.418 5.972 10.2m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m114.781 182.605.125-8.363c-10.058 1.969-4.031-2.597-8.02 6.75 3.403 2.098 2.188 1.352 7.891 1.613Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M155.79 303.98c-7.18 9.856-2.868 5.625 2.718 8.41 4.832-6.917 4.3-4.34-2.719-8.41m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M222.824 311.977c8.278-4.051 7.735-2.555.766-8.372-6.403 4.5-4.84 3.122-.766 8.372m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m278.734 176.105 2.403 5.926 4.14 1.098 2.602-7.348Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M169.879 321.309c7.754-2.391 6.184-4.243 6.184 3.414 6.98-7.34 2.18-4.621.019-9.286-7.305 3.075-3.328-1.812-6.203 5.872m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M92.375 177.344c2.965 5.668 1.676 4.683 7.063 4.402-.012-6.324 1.914-8.512-7.063-4.402m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M242.754 296.094c-10.598 8.754-.152 6.703 0 6.933 8.543-14.246 2.324-4.172 0-6.933m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M307.543 165.75c1.754 1.293-5.871 12.371 6.309 0-9.664-7.445-2.829-1.137-6.309 0m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M163.54 75.887c-.161 8.355 5.413 3.383 4.562.246-1.047-3.856-4.426-7.461-4.563-.246m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m200.598 104.3-5.555-7.26-2.223 7.35Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M37 183.145c.148 3.89 6.555 8.171 4.39.593-1.644-5.75-4.578-5.414-4.39-.593m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M184.777 104.242c5.61-6.379 6.328-.094 0-7.082-3.914 4.617-6.27 2.356 0 7.082m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M300.73 172.105c-.68.426-1.699 1.247-1.832 1.575-7.277 17.953-2.992-.715 1.832 5.64 1.415-3.539 7.918 4.075 0-7.21Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M173.77 87.484c-.27 7.5 9.226-.086 4.144-1.328-6.328-1.543-3.953-3.957-4.144 1.328m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M214.266 252.66c2.14 9.387-2.692 3.328 5.98 6.3-2.539-7.554.805-3.507-5.98-6.3m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m193.527 172.73-5.57-.347 3.23 8.258Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m65.938 170.133 7.593-3.352-3.746-3.676Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M212.336 75.887c.062 2.453 6.367 10.066 3.805.191-.004-.004-3.918-4.36-3.805-.191m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M77.563 174.445c3.972 3.97-7.711 6.121 3.23 2.586.238-.078 1.441-.66 1.684-.758.328-.136 1.085-1.277 1.71-1.828-5.082-2.343-.98-2.484-6.628 0Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M201.438 87.484c-.208 8.434 8.054-1.59 3.171-1.28-6.789.437-2.968-7.052-3.172 1.28m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m170.313 61.684 6.601 4.175c4.258-2.691 1.766 1.598.313-2.593l-4.012-4.606c2.597 4.266 2.48 2.617-2.903 3.024M188.465 227.383l4.441.008-3.066-7.325Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M79.258 146.98c4.207-.21 2.281.91 4.097-2.976l-5.414-1.61Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M134.922 299.094c5.476 4.34-8.781 5.523 3.387 2.176l3.296-2.176c-5.753-3.758 8.598-4.242-3.324-1.696Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="m275.203 309.297 6.871-5.219-4.683-.785Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M98.813 156.148c-.223-5.324 2.14-2.343-4.782-4.101 2.371 5.312-.844 3.902 4.781 4.101m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M93.031 147.406c.149-5.695 2.13-3.011-3.554-3.238.28 6.133-2.887 3.559 3.554 3.238m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M302.219 147.324c-4.211-.21-2.285.914-4.098-2.976l5.414-1.606Zm0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M282.66 156.5c.227-5.324-2.14-2.344 4.785-4.102-2.37 5.313.844 3.903-4.785 4.102m0 0"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M288.45 147.754c-.153-5.695-2.134-3.008 3.55-3.234-.277 6.128 2.89 3.554-3.55 3.234m0 0"
    ></path>
  </svg>
);

export default ShapeOne;
